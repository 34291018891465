@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.global-menu-icon {
  margin-left: auto;
  margin-right: 30px;
}

.header-logo {
  width: auto; 
  height: 40px; 
}

.highlight {
  color: #E15CBF; 
  margin-left: 10px;  
  margin-right: 10px; 
}

.subheader {  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(6vmin);
  color: black;
  width: 100%;
  
}

.footer {
  display: flex;             
  justify-content: flex-start; 
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 90px;
  background-color: #f1f1f1; 
  width: 100%;             
  box-sizing: border-box;  
}

.all-rights {
  font-weight: bold;
  font-size: small;
  text-align: start;
}

.responsive-image {
  width: 90%; 
  max-width: 1200px;
  height: auto; 
}

.subheader-title {  
  padding-left: 15%;
  padding-right: 15%;
}

.variant-link {
  background-color: white; 
  color: black;              
  padding: 10px 10px;        
  border: solid black;  
  border-width: 1px; 
  border-radius: 12px;        
  cursor: pointer;           
  font-size: 16px;           
  font-weight: bold;
  margin-right: 6px;
}

.variant-link:hover {
  background-color: #EEEEEE; 
}

.default-link {
  background-color: white; 
  color: black;              
  padding: 10px 10px;        
  border: solid black;  
  border-width: 2px; 
  border-radius: 12px;        
  cursor: pointer;           
  font-size: 16px;           
  font-weight: bold;
  margin-right: 6px;
}

.default-link:hover {
  background-color: #EEEEEE; 
}

.App-link {
  color: #61dafb;
}

